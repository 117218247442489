import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FullCalendarDateFilterService {
  private currentValueSignal = signal(0);
  private currentDateRange = signal({ start: null, end: null });
  constructor() { }

  getCurrentValue() {
    return this.currentValueSignal;
  }

  updateValue(newValue: number) {
    this.currentValueSignal.set(newValue);
  }

  setCurrentDateRange(filterDate) {
    this.currentDateRange = filterDate;
  }
}
